import React, { Fragment } from "react"
import { Link } from "gatsby"
import { Styled, css } from "theme-ui"
import FilteredMarkedGeography from "../../components/filtered-marked-geography"
import Layout from "gatsby-theme-blog/src/components/layout"
import SEO from "gatsby-theme-blog/src/components/seo"
import Footer from "gatsby-theme-blog/src/components/home-footer"
import Bio from "gatsby-theme-blog/src/components/bio"

const Posts = ({ posts, siteTitle, socialLinks }) => {
  const latestPosts = posts.slice(0, 3)
  const oldPosts = posts.slice(3, posts.length)

  function renderPostNode({ node: { title, slug, keywords, date, excerpt } }) {
    return (
      <Fragment key={slug}>
        <SEO title="Home" keywords={keywords || []} />
        <div>
          <Styled.h2
            css={css({
              mb: 1,
            })}
          >
            <Styled.a
              as={Link}
              css={{
                textDecoration: `none`,
              }}
              to={slug}
            >
              {title || slug}
            </Styled.a>
          </Styled.h2>
          <small>{date}</small>
          <Styled.p>{excerpt}</Styled.p>
        </div>
      </Fragment>
    )
  }

  return (
    <Layout location={{ pathname: `${__PATH_PREFIX__}/` }} title={siteTitle}>
      <SEO title="Home" />
      <main>
        <div style={{ display: "flex", flexFlow: "row wrap" }}>
          <div
            css={css({ flex: ["1, 100%", "1 100%", "1 70%"], padding: "1em" })}
          >
            <Styled.h2>Where Have We Been?</Styled.h2>
            <Styled.p>
              The highlighted regions are where we have been. Click to read more
              about our adventure.
            </Styled.p>
            <FilteredMarkedGeography
              includedCountries={[
                "IRL",
                "GBR",
                "USA",
                "MEX",
                "JAM",
                "LCA",
                "FRA",
              ]}
              render={{
                width: 1400,
                height: 1400,
                scale: 600,
              }}
              center={[-80, 20]}
            />
          </div>
          <div css={css({ flex: ["1, 100%", "1 100%", "1 30%"], padding: "1em" })}>
            <Styled.h2>Adventures</Styled.h2>
            {latestPosts.map(renderPostNode)}
          </div>
          <div style={{ flex: "1 100%" }}>{oldPosts.map(renderPostNode)}</div>
        </div>
      </main>
      <Bio></Bio>
      <Footer socialLinks={socialLinks} />
    </Layout>
  )
}

export default Posts
